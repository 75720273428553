
import eventBus from './eventBus.js';
import api from './api.js';
import {getTime, formData} from "./methods.js"



export default {
	eventBus,
	api,
	getTime,
	formData,
	// getTime:methods.getTime,
	// formData:methods.formData
}