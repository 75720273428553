<template>
	<div class="login_box">
		<div class="titile_box">后台管理</div>
		<el-form :model="param" :rules="rules" ref="param" label-width="100px">
			<el-form-item prop="t_login_phone">
				<el-input prefix-icon="el-icon-user" placeholder="用户名" v-model="param.t_login_phone" @keyup.enter.native="submitForm('param')"></el-input>
			</el-form-item>
			<el-form-item prop="t_password">
				<el-input type="password" prefix-icon="el-icon-lock" show-password placeholder="密码" v-model="param.t_password" @keyup.enter.native="submitForm('param')" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button class="button_box" type="primary" :loading="loadingShow" @click="submitForm('param')">提交</el-button>
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	export default {
		data() {
			var checkAge = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('用户名不能为空'));
				}else{
					return callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					return callback(new Error('请输入密码'));
				} else {
					if (value.length < 3) {
						return callback(new Error('密码需要至少3位'));
					} else {
						return callback();
					}
				}
			};
			return {
				param: {
					t_login_phone: '',
					t_password: ''
				},
				rules: {
					t_login_phone: [{
						validator: checkAge,
						trigger: 'blur'
					}],
					t_password: [{
						validator: validatePass,
						trigger: 'blur'
					}]
				},
				loadingShow:false
			};
		},
		methods: {
			submitForm(formName) {
				let that = this
				this.$refs[formName].validate((valid) => {
					console.log("valid", valid)
					if (valid) {
						that.save()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async save() {
				let that = this
				let param = {
					url: "userLogin",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					console.log("res", res)
					if(res.status == 0){
						that.$router.push('/home/welcome');
					}
				})
				this.loadingShow = false
			}

		}
	}
</script>

<style>
	.login_box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #2d3a4b;
		height: 100%;
	}

	.titile_box {
		font-size: 30px;
		font-weight: bold;
		color: white;
		margin-bottom: 50px;
	}

	.login_box .el-form {
		width: 20%;
		min-width: 300px;
	}

	.login_box .el-form>el-form-item {
		margin-top: 20px;
	}

	.login_box .el-form .el-input__inner {
		background-color: #283443 !important;
		border: 1px solid rgba(255, 255, 255, 0.2) !important;
		color: white !important;
		height: 50px !important;
		line-height: 50px !important;
	}

	.login_box .el-form .el-form-item__label {
		display: none;
		width: 0 !important;
	}

	.login_box .el-form .el-form-item__content {
		margin-left: 0 !important;
	}

	.login_box .el-form .button_box {
		width: 100%;
	}

	/* .input_box {
		width: 20%;
		min-width: 300px;
		margin-top: 20px;
		background: #2d3a4b;
	}*/
</style>
