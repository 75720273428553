import { 
	Row,
	Col,
	Button,
	Popover,
	Form,
	FormItem,
	Input,
	InputNumber,
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Table,
	TableColumn,
	Divider,
	Select,
	Option,
	DatePicker,
	Pagination,
	Loading,
	Message
} from 'element-ui';



export default {
	Row,
	Col,
	Button,
	Popover,
	Form,
	FormItem,
	Input,
	InputNumber,
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Table,
	TableColumn,
	Divider,
	Select,
	Option,
	DatePicker,
	Pagination,
	Loading,
	Message
}