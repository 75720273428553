<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>


export default {
	name: 'App',
	components: {
		
	}
}
</script>

<style>
	body,html{
		width: 100%;
		height: 100%;
		margin: 0;
	}
	#app {
		height: 100%;
		color: #333333;
	}
	/* 全局 */
	.right_min_width{
		min-width: 400px;
	}
	.leftMenuBox{
		width: 180px !important;
		background-color: rgb(84, 92, 100);
		transition: width 0.2s;
		-moz-transition: width 0.2s;	/* Firefox 4 */
		-webkit-transition: width 0.2s;	/* Safari 和 Chrome */
		-o-transition: width 0.2s;	/* Opera */
	}
	.leftMenuBox64{
		width: 64px !important;
		transition: width 0.2s;
		-moz-transition: width 0.2s;	/* Firefox 4 */
		-webkit-transition: width 0.2s;	/* Safari 和 Chrome */
		-o-transition: width 0.2s;	/* Opera */
	}
	.rightContent{
		width: calc(100% - 180px);
		transition: width 0.2s;
		-moz-transition: width 0.2s;	/* Firefox 4 */
		-webkit-transition: width 0.2s;	/* Safari 和 Chrome */
		-o-transition: width 0.2s;	/* Opera */
	}
	.rightContent64{
		width: calc(100% - 64px);
		transition: width 0.2s;
		-moz-transition: width 0.2s;	/* Firefox 4 */
		-webkit-transition: width 0.2s;	/* Safari 和 Chrome */
		-o-transition: width 0.2s;	/* Opera */
	}
	.el-pagination{  
		display: flex;
		justify-content: center;
		align-items: center;
		padding:20px 0;
		box-sizing: border-box;
	}
	
	.list_img_box{
		width: 30px;
		height: 30px;
		border-radius: 5px;
	}
	.list_img_box>img{
		width: 100%;
		height: auto;
	}
	
	/* 左边的线条 */
	.money_box{
		height: 20px;
		border-left: 4px solid #409eff;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 13px;
	}
	.money_box>div{
		margin-left: 20px;
	}
	
	.padding16{
		padding: 0 20px;
		box-sizing: border-box;
	}
	.x_v_center{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.x_start_v{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.v_x_center{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>
