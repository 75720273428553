const getTime = function(date) {
	let year = date.getFullYear(),
		month = date.getMonth() + 1,
		day = date.getDate(),
		h = date.getHours(),
		m = date.getMinutes(),
		s = date.getSeconds()

	month = month < 10 ? '0' + month : month;
	day = day < 10 ? '0' + day : day;
	h = h < 10 ? '0' + h : h;
	m = m < 10 ? '0' + m : m;
	s = s < 10 ? '0' + s : s;

	let currD = {
		year,
		month,
		day,
		h,
		m,
		s
	}
	return currD
}
const formData = function(params) {
	let formData = new FormData()

	for (let item in params) {
		if (params[item] != 0 && params[item] == '') {
			params[item] = null
		}
		if (params[item] || params[item] == 0) {
			formData.append(item, params[item])
		}
	}
	return formData
}



// export default = {
// 	getTime,
// 	formData
// }
module.exports = {
	getTime,
	formData
}