import Vue from 'vue'
// 0. 如果使用模块化机制编程，导入Vue和VueRouter，要调用 Vue.use(VueRouter)
import VueRouter from 'vue-router'
Vue.use(VueRouter) 
import login from "../pages/login.vue"

// 2. 定义路由
const routes = [
   { path: '/', name:'login', component: login },
   { path: '/login', name:'login', component: login},
   {path: '/home',name: 'home',component: () => import('../pages/home.vue'),
		children:[
			{path: '/',name: 'welcome',component: () => import('../pages/welcome.vue')},
			{path: '/home/welcome',name: 'welcome',component: () => import('../pages/welcome.vue')},
			{path: '/home/InvitedUsers',name: 'InvitedUsers',component: () => import('../pages/InvitedUsers.vue')},
			{path: '/home/invitedAnchor',name: 'invitedAnchor',component: () => import('../pages/invitedAnchor.vue')},
			{path: '/home/rechargeTotal',name: 'rechargeTotal',component: () => import('../pages/rechargeTotal.vue')},
			{path: '/home/income',name: 'income',component: () => import('../pages/income.vue')},
			{path: '/home/userCenter',name: 'userCenter',component: () => import('../pages/userCenter.vue')},
			{path: '/home/withdrawal',name: 'withdrawal',component: () => import('../pages/withdrawal.vue')},
			{path: '/home/changePassword',name: 'changePassword',component: () => import('../pages/changePassword.vue')},
			{path: '/home/myGuildUserPutList',name: 'myGuildUserPutList',component: () => import('../pages/myGuildUserPutList.vue')}
		]
   }
]

// 3. 创建 router 实例，然后传 `routes` 配置  
export default new VueRouter({
  routes // (缩写) 相当于 routes: routes
})